.home {
    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        font-size: $font-size--1;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        font-family: $font-family--heading;
        color: $color-text;
        margin: 0 0 35px;

        &:before {
            display: none;
        }

        &--white {
            color:$color-white;
        }

        &__subtitle {
            display: flex;
            align-items: center;
            font-size: 1.563rem;
            font-weight: $font-weight-light;
            font-style: italic;
            font-family: $font-family;
            text-transform: none;

            &:before {
                content: '';
                display: inline-block;
                width: 5px;
                height: 5px;
                background-color: $color-dark;
                border-radius: $border-radius--round;
                margin: 0 15px;
            }

            &--white {
                &:before {
                    background-color: $color-white;
                }
            }
        }
    }

    .button {
        svg {margin-right: 8px;}
    }

    &__nav {
        position: relative;
        display: flex;

        .swiper-button {
            position: relative;
            width: 40px;
            height: 40px;
            background-color: $color-third;
            border-radius: $border-radius--round;
            padding: 0;
            margin: 0 0 0 15px;
            top: 0;
            border: none;
            pointer-events: auto;

            &:hover, &:focus {
                background-color: $color-dark;

                svg {
                    fill: $color-white;
                }
            }

            svg {
                width: 40px;
                height: 40px;
                fill: $color-dark;
                transition: $duration;
            }

            &:after {
                display: none;
            }

            &-prev {
                left: 0;
            }

            &-next {
                right: 0;
            }
        }
    }

    //======================================================================================================
    // A la une
    //======================================================================================================
    .alu {
        position: relative;
    
        > .container {
            position: relative;
        }
    
        .container {
            &--content {
                position: relative;
                height: 100%;
                max-width: 1140px;
            }
    
            &--nav {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                pointer-events: none
            }
        }

        &__nav {
            top: calc(50% - 45px);
            flex-direction: column;
            height: 90px;
            justify-content: space-between;
            align-items: flex-end;
        }
    
        .slideshow {
            height: 500px;
            width: 100%;
    
            img, video {
                position: absolute;
                object-fit: cover;
                object-position: top;
                width: 100%;
                height: 100%;
            }
    
            &__container {
                display: inherit;
                position: relative;
                width: 100%;
                height: 100%;
            }
    
            &__content {
                display: flex;
                box-sizing: border-box;
                position: absolute;
                left: 0;
                bottom: 40px;
                height: auto;
                padding: 10px 20px;
                background: rgba(255, 255, 255, 0.7);
                margin-right: 50px;
            }
    
            &__title {
                margin: 0;
                font-size: 1.563rem;
                line-height: 1.875rem;
                font-weight: $font-weight-medium;
                color: $color-text;
            }
    
            &--video {
                img {
                    display: none;
                }
            }        
        }
    }
    
    //======================================================================================================
    // Accès rapides
    //======================================================================================================
    .access {
        padding: 80px 0;

        &__list {
            display: flex;
            justify-content: space-between;
        }

        &__item {
            position: relative;
            width: 170px;
            display: flex;
            flex-direction: column;
            align-items: center;

            &:before {
                content: '';
                position: absolute;
                top: -10px;
                width: 100%;
                height: 65px;
                background-color: $color-white;
                z-index: 2;
            }

            &:hover, &:focus {
                .access {
                    &__svg-container {
                        svg {
                            background-color: $color-third;
                        }

                        &:before,
                        &:after {
                            border-color: $color-third;
                            transition: transform .35s ease-in-out, border .3s ease-in-out;
                        }

                        &:after {
                            transform: translate(-50%,-50%) rotate(315deg);
                        }
                    }
                }
            }
        }

        &__svg-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 130px;
            height: 130px;
            border-radius: $border-radius--round;

            svg {
                position: relative;
                z-index: 2;
                width: 100%;
                height: 100%;
                background-color: $color-white;
                border: 1px solid $color-third;
                border-radius: $border-radius--round;
                transition: $duration;
            }

            &:before {
                border: 1px solid $color-main;
                z-index: -1;
            }

            &:after {
                border: 1px solid $color-bg--transparent;
                z-index: 2;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                height: 150px;
                width: 150px;
                border-radius: $border-radius--round;
                left: 50%;
                top: 50%;
                clip-path: polygon(100% 0,0 0,100% 100%);
                background-color: transparent;
                transform: translate(-50%,-50%) rotateZ(135deg);
                transition: transform .35s ease-in-out, border .3s ease-in-out;
            }
        }

        &__label {
            margin-top: 40px;
            text-align: center;
            text-transform: uppercase;
            font-weight: $font-weight-light;
            letter-spacing: 0.05rem;
            font-size: 1.125rem;
            line-height: 1.563rem;

            span {
                display: block;
                font-weight: $font-weight-semibold;
            }
        }
    }

    //======================================================================================================
    // Actualités
    //======================================================================================================
    .posts {
        position: relative;
        padding: 60px 0;
        background-color: $color-main;
        overflow: hidden;

        &--empty {
            display: none;
        }

        > .container {
            position: relative;

            &:before {
                content:'';
                position: absolute;
                top: -115px;
                right: -215px;
                width: 500px;
                height: 500px;
                background: url(/wp-content/themes/pluduno/assets/src/images/sprite-images/shapes/Ellipses-actu.svg);
                background-size: contain;
            }
        }

        &__list {
            display: flex;
            position: relative;
        }

        &__item-wrapper {
            display: block;
            margin: 0 30px 30px 0;

            &:last-child {
                margin-right: 0;
            }
        }

        &__item {
            display: flex;
            flex-direction: column;
            width: 370px;
            height: 500px;
            box-shadow: $shadow--variant, 0 0 0 1px $color-white;
            background-color: $color-main;
            transition: .35s;

            &:hover, &:focus {
                background-color: $color-white;
                box-shadow: none;

                .posts {
                    &__item {
                        &__taxo,
                        &__title,
                        &__intro {
                            color: $color-text;
                        }
                    }
                }
            }

            &__container-img {
                width: 340px;
                height: 260px;
                border-radius: 0px 0px 150px 0px;
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) no-repeat center $color-light;
                overflow: hidden;
                flex: none;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__content {
                padding: 25px 20px 20px;
            }

            &__taxo,
            &__title,
            &__intro {
                color: $color-white;
                transition: $duration;
            }

            &__taxo {
                display: flex;
                align-items: center;
                font-size: 1.125rem;
                line-height: 1.25rem;
                font-style: italic;
                font-weight: $font-weight-light;

                &:before {
                    content: '';
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 5px;
                    width: 15px;
                    height: 15px;
                    background: url('/wp-content/themes/pluduno/assets/src/images/sprite-images/pictos/ic-categorie.svg') no-repeat;
                    background-size: contain;
                }
            }

            &__title {
                font-size: 1.563rem;
                line-height: 1.875rem;
                font-weight: $font-weight-medium;
                margin: 15px 0 5px;
            }

            &__intro {
                line-height: 1.25rem;
            }
        }

        &__template {
            display: flex;
            justify-content: flex-end;

            &__btn {
                width: 370px;
                margin: 0;
            }
        }
    }

    //======================================================================================================
    // Agenda
    //======================================================================================================
    .events {
        position: relative;
        padding: 60px 0 100px;

        &--empty {
            display: none;
        }

        > .container {
            position: relative;

            &:before {
                content:'';
                position: absolute;
                top: 55px;
                left: -310px;
                width: 280px;
                height: 280px;
                background: url(/wp-content/themes/pluduno/assets/src/images/sprite-images/shapes/Ellipses-agenda.svg);
                background-size: contain;
            }
        }

        &__header {
            align-items: flex-end;
        }

        &__nav {
            margin-bottom: 40px;
        }

        .swiper-slide {
            display: block;
            margin: 0 30px 0 0;
            width: 370px;
        }

        &__item {
            display: flex;
            flex-direction: column;
            position: relative;

            &:hover, &:focus {
                .events {
                    &__item {
                        &__container-img {
                            &:after {
                                opacity: 1;
                            }
                        }

                        &__title {
                            color: $color-main;
                        }
                    }
                }
            }

            &__container-img {
                position: relative;
                width: 100%;
                height: 280px;
                background: url(/wp-content/themes/noyau/assets/images/icon-events.png) no-repeat center $color-light;
                flex: none;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    background-color: rgba(29, 29, 27, .65);
                    transition: $duration;
                }
            }

            &__date {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 90px;
                width: 140px;
                background-color: rgba(29, 29, 27, .85);
                border-radius: 0px 0px 50px 0px;
                color: $color-white;

                &--same {
                    width: 110px;
                }

                &__day {
                    font-family: $font-family--heading;
                    font-weight: $font-weight-black;
                    font-size: 2rem;
                    line-height: 2rem;
                }

                &__month {
                    font-weight: $font-weight-light;
                    font-size: $font-size--text-small;
                    line-height: 1rem;
                }

                > p {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                &__separation {
                    width: 1px;
                    height: 45px;
                    background-color: $color-white;
                    margin: auto 10px;
                }
            }

            &__title {
                color: $color-text;
                font-size: 1.563rem;
                line-height: 1.875rem;
                font-weight: $font-weight-medium;
                margin: 20px 0 0;
                transition: $duration;
            }
        }

        &__template {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            margin-top: 40px;

            &__btn {
                width: 570px;
                margin: 0;

                &--prop {
                    background-color: $color-dark;
                    border-color: $color-dark;
                    color: $color-white;

                    svg {
                        fill: $color-white;

                        path {
                            fill: $color-white;
                        }
                    }

                    &:hover, &:focus {
                        background-color: $color-white;
                        border-color: $color-dark;
                        color: $color-dark;

                        svg {
                            fill: $color-dark;

                            path {
                                fill: $color-dark;
                            }
                        }
                    }
                }
            }
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home {
        .alu {        
            .container {
                &--content {
                    max-width: 920px;
                }
            }
        
            .slideshow {
                height: 400px;
            }
        }

        .access {
            padding: 70px 0 60px;
    
            &__item {
                width: 140px;
            }
    
            &__svg-container {
                width: 110px;
                height: 110px;
    
                &:before,
                &:after {
                    height: 130px;
                    width: 130px;
                }
            }
    
            &__label {
                letter-spacing: 0.02rem;
                font-size: 1rem;
                line-height: 1.25rem;
            }
        }

        .posts {
            > .container {
                &:before {
                    top: -110px;
                    right: -215px;
                }
            }

            &__item-wrapper {
                margin: 0 20px 30px 0;
    
                &:last-child {
                    margin-right: 0;
                }
            }
    
            &__item {
                width: 300px;
                height: 465px;
    
                &__container-img {
                    width: 280px;
                    height: 210px;
                    border-radius: 0px 0px 100px 0px;
                }
    
                &__content {
                    padding: 25px 20px 15px;
                }
    
                &__title {
                    font-size: 1.25rem;
                    line-height: 1.563rem;
                }
    
                &__intro {
                    font-size: $font-size--text-small;
                }
            }
    
            &__template {    
                &__btn {
                    width: 300px;
                }
            }
        }

        .events {
            padding: 60px 0 80px;
    
            .swiper-slide {
                margin: 0 20px 0 0;
                width: 300px;
            }
    
            &__item {    
                &__container-img {
                    height: 230px;
                }
    
                &__title {
                    font-size: 1.25rem;
                    line-height: 1.563rem;
                    margin: 15px 0 0;
                }
            }
    
            &__template {   
                &__btn {
                    width: 460px;
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .home {
        .alu {
            .slideshow {
                height: 320px;  

                .container {
                    &--content {
                        max-width: 600px;
                    }
                }
    
                &--video {
                    video {
                        display: none;
                    }
    
                    img {
                        display: block;
                    }
                }

                &__content {
                    bottom: 25px;
                }
            }
        }

        .access {    
            padding: 60px 0 10px;

            &__list {
                justify-content: space-around;
                flex-wrap: wrap;
            }

            &__item {
                width: 170px;
                margin-bottom: 50px;
            }
    
            &__svg-container {
                width: 130px;
                height: 130px;
    
                &:before,
                &:after {
                    height: 150px;
                    width: 150px;
                }
            }

            &__label {
                letter-spacing: 0.05rem;
                font-size: 1.125rem;
                line-height: 1.563rem;
            }
        }

        .posts {
            > .container {
                &:before {
                    top: -115px;
                    right: -215px;
                }
            }

            &__list {
                flex-wrap: wrap;
            }

            &__item-wrapper {
                margin: 0 20px 30px 0;

                &:nth-child(2) {
                    margin-right: 0;
                }
    
                &:last-child {
                    margin-right: 0;

                    .posts {
                        &__item {
                            flex-direction: row;
                            height: 210px;
                            width: 100%; 

                            &__content {
                                padding: 20px 40px;
                            }
                        }
                    }
                }
            }
    
            &__template {    
                &__btn {
                    width: 100%;
                }
            }
        }

        .events {
            &__template {   
                &__btn {
                    width: 300px;
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .home {
        .alu {
            .container {
                &--nav {
                    position: relative;
                    height: auto;
                }
            }

            &__nav {
                top: 0;
                flex-direction: row;
                height: auto;
                margin-top: 20px;
                justify-content: center;

                .swiper-button {
                    margin: 0 5px;
                }
            }

            .swiper-wrapper {
                height: 220px;
            }

            .slideshow {
                height: auto;

                &--video {
                    height: 220px;
                }

                .container {
                    &--content {
                        max-width: 280px;
                        display: flex;
                        justify-content: center;
                    }
                }

                &__content {
                    margin: auto;
                    padding: 5px 20px;
                    bottom: 15px;
                    left: auto;
                    right: auto;
                }

                &__title {
                    font-size: 1.125rem;
                    line-height: 1.25rem;
                }
            }
        }

        .access {
            padding: 30px 0 20px;
            ;
            &__list {
                justify-content: space-between;
            }
    
            &__item {
                width: 140px;
            }
    
            &__svg-container {
                width: 110px;
                height: 110px;
    
                &:before,
                &:after {
                    height: 130px;
                    width: 130px;
                }
            }
    
            &__label {
                letter-spacing: 0.02rem;
                font-size: 1rem;
                line-height: 1.25rem;
            }
        }

        .posts {
            >.container {
                &:before {
                    top: 340px;
                    right: -285px;
                }
            }

            &__list {
                flex-wrap: wrap;
            }

            &__item-wrapper {
                margin: 0 0 20px;
    
                &:last-child {
                    margin: 0 0 30px;

                    .posts {
                        &__item {
                            height: 465px;
                            flex-direction: column;

                            &__content {
                                padding: 25px 20px;
                            }
                        }
                    }
                }
            }

            &__item {
                &__content {
                    padding: 25px 20px;
                }
            }
        }

        .events {
            >.container {
                &:before {
                    left: -420px;
                }
            }

            &__template {   
                flex-direction: column-reverse;

                &__btn {
                    &--prop {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
