.breadcrumb {
    background-color: $color-main;

    ul  {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-white;
        padding: 0;

        a {
            color: $color-white;
            line-height: 1.25rem;

            &:hover {
                text-decoration: underline;
                color: $color-white;
            }
        }

        &:after {    
            content: "";
            display: inline-block;
            width: 26px;
            height: 26px;
            background: url(/wp-content/themes/pluduno/assets/src/images/sprite-images/pictos/ic-nav-chevron-right-white.svg) no-repeat;
            background-size: contain;
            margin: 0 2px;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}
