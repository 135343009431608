#nav-main {
    position: relative;
    padding: 0 200px 0 330px;

    .menu__nav__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        transition: $duration ease-out;
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:hover,
        &.active,
        &:focus {
            .nav-main-link {
                color: $color-main;

                &:after {
                    opacity: 1;
                }
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 120px;
            padding: 0;
            color: $color-text;
            letter-spacing: 0.05rem;
            font-size: 1.125rem;
            line-height: 1.25rem;
            font-weight: $font-weight-semibold;
            text-transform: uppercase;
            overflow: hidden;

            > span {
                font-size: 1.125rem;
                line-height: 1.25rem;
                font-weight: $font-weight-light;
                font-style: italic;
                text-transform: capitalize;
            }

            &:after {                
                content: '';
                position: absolute;
                opacity: 0;
                left: 0;
                bottom: -42px;
                height: 70px;
                width: 70px;
                border-radius: $border-radius--round;
                border: 1px solid $color-main;
                clip-path: polygon(100% 0,0 0,100% 100%);
                background-color: transparent;
                transform: rotate(-45deg);
                transition: all $duration;
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            top: 120px;
            width: 100%;
            padding: 30px 15px;

            &__close {
                display: none;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: -1000px;
                height: 100%;
                width: 5000px;
                background-color: rgba(52, 118, 153, .95);
                box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.08);
            }

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
                z-index: 1;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                padding: 0;
                line-height: initial;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 50px;
                    padding: 5px 40px 5px 0;
                    color: $color-white;
                    font-weight: $font-weight-semibold;

                    &:before,
                    &:after {
                        content: '';
                        position: absolute;
                        transition: all $duration;
                    }

                    &:before {
                        transform: translateY(-50%);
                        top: 50%;
                        left: 15px;
                        width: 16px;
                        height: 16px;
                        background: url('/wp-content/themes/pluduno/assets/src/images/sprite-images/pictos/ic-categorie-white.svg') no-repeat;
                        background-size: contain;
                        opacity: 0;
                        transition: .35s;
                    }

                    &:after {
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        background-color: $color-white;
                        opacity: .5;
                    }

                    &:hover {
                        padding: 5px 0 5px 40px;

                        &:before {
                            opacity: 1;
                        }

                        &:after {
                            height: 2px;
                            opacity: 1;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    padding: 5px 15px 5px 10px;
                    border-bottom: 0;
                    font-size: $font-size--text-small;
                    font-weight: $font-weight;
                    text-transform: initial;
                    line-height: initial;

                    &:before {
                        left: 10px;
                        width: 10px;
                        height: 10px;
                        background: url('/wp-content/themes/pluduno/assets/src/images/sprite-images/pictos/ic-categorie-white.svg') no-repeat;
                        background-size: contain;
                        opacity: 0;
                        transition: .35s;
                    }

                    &::after {
                        display: none;
                    }

                    &:hover {
                        padding: 5px 0 5px 25px;
                    }
                } 
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {
    #nav-main {
        padding: 0 170px 0 240px;

        .nav-main-item {
            .nav-main-link {
                letter-spacing: 0.02rem;
                font-size: 1rem;
                line-height: 1.250rem;
                height: 110px;
            }

            .sub-menu {
                top: 110px;
            }
        }
    }
}

.nav-main {
    &__button,
    &__icon {
        display: none;
    }
}


// 960
@media screen and (max-width: $medium) {
    // Responsive button label
    .nav-main {
        &__icon {
            z-index: 16;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0;
            line-height: 0;
            width: 40px;
            height: 40px;
            border-radius: $border-radius--round;
            background-color: $color-dark;
            cursor: pointer;

            &__svg-container {
                svg {
                    display: flex;
                }
            }
        }

        &__close {
            display: none;
            transform: rotate(45deg);
        }

        &__wrapper {
            z-index: 15;
            padding: 0 20px;
            background: $color-main;
            position: absolute;
            height: auto;
            min-height: 100vh;
            min-width: 100vw;
            width: 100%;
            left: 50%;
            top: 0;
            transform: translateX(50%);
            box-shadow: $shadow--variant;
            transition: .45s;
        }
        &__button {
            display: block;
            z-index: -1;
            position: absolute;

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main__icon {
                    position: absolute;
                    right: 0;
                    top: 35px;

                    .nav-main__icon__svg-container {
                        display: none;
                    }

                    .nav-main__close {
                        display: flex;
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }

                ~ .nav-main__wrapper {
                    transform: translateX(-50%);
                }
            }
        }
    }

    #nav-main {
        padding: 0 150px 0 240px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .menu__nav__list {
            flex-flow: column;
            width: 100%;
            margin: 100px auto 0;
            max-width: 640px;
            height: 100%;
        }

        .nav-main-item {
            width: 100%;
            border-bottom: 1px solid $color-white;

            &:last-child {
                border: none;
            }

            &.active,
            &:hover,
            &:focus {
                .nav-main-link {
                    color: $color-white;
                }
            }

            &.active {
                .sub-menu {
                    transform: translateX(0);

                    &__close {
                        position: absolute;
                        top: 35px;
                        left: calc((100% - 620px) / 2);
                        display: flex;
                        z-index: 16;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 0;
                        line-height: 0;
                        width: 40px;
                        height: 40px;
                        border-radius: $border-radius--round;
                        background-color: $color-dark;
                        border: none;
                        padding: 0;
                        cursor: pointer;

                        svg {
                            fill: $color-white;
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }

            .nav-main-link {
                color: $color-white;
                height: 60px;

                &:after {
                    display: none;
                }
            }

            .sub-menu {
                position: absolute;
                display: flex;
                background-color: $color-main;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                padding: 0 20px;
                transform: translateX(100%);
                transition: .45s;

                &:after {
                    display: none;
                }

                .nav-drop {
                    column-count: 1;
                }
                
                > ul {
                    margin-top: 100px;
                    max-width: 640px;
                }
    
                li:last-child {
                    a {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    #nav-main {
        padding: 0 200px 25px 0px;
        align-items: flex-end;

        .menu__nav__list {
            margin: 90px auto 0;
            max-width: 300px;
        }

        .nav-main-item {
            &.active {
                .sub-menu {
                    &__close {
                        left: calc((100% - 300px) / 2);
                    }
                }
            }
            .sub-menu {
                > ul {
                    margin-top: 100px;
                    max-width: 300px;
                }
            }
        }
    }
}
