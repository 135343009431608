.cover {
    position: relative;

    &__image-wrapper {
        width: 100%;
        height: 500px;
        background: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        padding: 40px 0 45px;
    }

    &__title {
        margin: 0;
    }

    &__lead-wrapper {
        p {
            margin: 20px 0 0;
            font-weight: $font-weight;
            font-size: 1.25rem;
            line-height: 1.5rem;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            height: 400px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 320px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {        
        &__image-wrapper {
            height: 220px;
        }
    }

}
