.footer {
    background-color: $color-main;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: url('/wp-content/themes/pluduno/assets/src/images/carte.jpg') no-repeat;
        background-size: contain;
        background-position: right;
    }

    &__top,
    &__bottom {
        position: relative;
        z-index: 1;
    }

    &__infos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 600px;

        &__logo {
            display: flex;
            align-items: flex-end;
            margin-top: 42px;
            width: 100%;

            img {
                width: 200px;
                height: 105px;
                margin-right: 100px;
            }
        }

        &__address,
        &__hours {
            margin: 35px 0 20px;
            width: 300px;
        }

        &__title {
            font-family: $font-family--heading;
            font-size: $font-size--4;
            font-weight: $font-weight-medium;
            line-height: 1.875rem;
            color: $color-white;
            margin: 0 0 10px;
        }

        &__text {
            color: $color-white;
            line-height: 1.25rem;
        }

        &__contact {
            width: 270px;
            margin: 0 0 5px;

            svg {
                margin-right: 8px;
            }
        }

        &__phone {
            position: relative;
            display: inline-flex;
            align-items: center;
            margin-top: 15px;
            font-weight: $font-weight-semibold;
            color: $color-white;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                height: 1px;
                width: 0;
                background-color: $color-white;
                transition: $duration;
            }

            &:hover,
            &:focus {
                color: $color-white;

                &:after {
                    width: 100%;
                }
            }
        }
    }

    &__menu {
        display: flex;
        padding: 27px 0;

        &__item {
            position: relative;
            margin-right: 20px;

            &:after {
                content: '';
                display: inline-block;
                vertical-align: middle;
                height: 18px;
                width: 1px;
                background-color: $color-white;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }

        &__link {
            color: $color-white;
            margin-right: 20px;
            font-size: $font-size--text-small;

            &:hover,
            &:focus {
                text-decoration: underline;
                color: $color-white;
            }
        }
    }
}

// 1200
@media screen and (max-width: $large) {
    .footer {
        &:after {
            right: -80px;
        }

        &__infos {
            width: 480px;

            &__title {
                font-size: 1.25rem;
                line-height: 1.563rem;
            }

            &__text {
                font-size: $font-size--text-small;
                line-height: 1.25rem;
            }

            &__logo {
                margin-top: 30px;
                width: 460px;

                img {
                    width: 160px;
                    height: 85px;
                    margin-right: 80px;
                }
            }

            &__hours,
            &__address {
                width: 240px;
                margin: 30px 0 25px;
            }

            &__contact {
                width: 220px;
                padding: 0;
                margin: 0;
            }

            &__phone {
                font-size: $font-size--text-small;
                line-height: 1.25rem;
            }
        }

        &__menu {
            flex-wrap: wrap;
            padding: 5px 0 20px;
            width: 540px;

            &__item {
                display: flex;
                align-items: center;
                height: 30px;
                margin-right: 15px;

                &:nth-child(3){
                    &:after {
                        display: none;
                    }
                }
            }

            &__link {
                margin-right: 15px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        &:after {
            display: none;
        }

        &__infos {
            width: 100%;

            &__logo {
                width: 100%;
                justify-content: flex-start;

                img {
                    margin-right: 150px;
                }
            }

            &__hours, 
            &__address {
                width: 100%;
                flex: 1;
                margin: 30px 0;
            }

            &__contact {
                margin-bottom: 5px;
            }
        }

        &__menu {
            padding: 5px 0 40px;

            &__item {
                height: 40px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        &__infos {
            flex-direction: column;

            &__logo,
            &__hours,
            &__address {
                margin: 0 0 30px;
            }

            &__logo {
                align-items: flex-start;
                flex-direction: column;
                margin-top: 55px;

                img {
                    margin-right: 0;
                    margin-bottom: 30px;
                }
            }

            &__contact {
                margin-bottom: 0;
            }
        }

        &__menu {
            width: 100%;

            &__item {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(4){
                    &:after {
                        display: none;
                    }
                }
                
                &:nth-child(3) {
                    &:after {
                        display: inline-block;
                    }
                }
            }
        }
    }
}